const { Notifications } = require("@/graphql/notifications/Notifications.ts");

class NotificationsComponent {
  async saveNotification(apollo, notification) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!notification) {
        return {
          IsSuccess: false,
          Message: "Keine Benachrichtigung gefunden.",
        };
      }
      const savedNotification = await apollo
        .mutate({
          mutation: Notifications.Mutations.SaveNotification,
          fetchPolicy: "no-cache",
          variables: {
            notification: notification,
          },
        })
        .then(({ data }) => data?.saveNotification)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      if (!savedNotification) {
        return {
          IsSuccess: false,
          Message: "Die Benachrichtigung konnte nicht gespeichert werden.",
        };
      }
      return savedNotification;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}

module.exports = NotificationsComponent;
