<template lang="pug">
#BookingConfirmed
  .content-wrapper(v-if="voucher")
    .row
      .col-12.mt-5.text-center
        img(:src="`${publicPath}images/touribook-branding-150x150.png`", style="height: 120px")
      .col-12.mt-3.text-center
        h2 {{$t("booking-confirmed.headline")}}
      .col-12.text-center
        h4 {{ voucher.BookedAt | formatDate }} {{$t("constants.until")}} {{ moment(voucher.BookedAt).add(nights, "days").toDate() | formatDate }}

      .col-12.mt-5
        template(v-if="bookingRequest")
          p(v-if="bookingRequest.Title == 'Herr'") {{$t("constants.AddressM")}} {{bookingRequest.LastName}},
          p(v-else) {{$t("constants.AddressF")}} {{bookingRequest.LastName}},
        p {{$t("booking-confirmed.text1")}}
          b  {{ voucher.BookedAt | formatDate }} {{$t("constants.until")}} {{ moment(voucher.BookedAt).add(nights, "days").toDate() | formatDate  }}.
        p {{$t("booking-confirmed.text2")}}
        h6.mt-4 {{$t("booking-confirmed.yourHost")}}
        template(v-if="host")
          p {{host.Match1}}
            |
            br
            | {{host.PAdresse4}}
            |
            br
            | {{host.PPLZ}} {{host.PORT}}
          p
            i
              font-awesome-icon(:icon="['fas', 'phone-alt']")
            | &nbsp; {{host.TELEFON1}}
            |
            br
            i
              font-awesome-icon(:icon="['fas', 'envelope']")
            a(:href="'mailto:' + host.EMAIL1") &nbsp; {{host.EMAIL1}}
        p.mt-4 {{$t("booking-confirmed.text3")}}
        p.mb-0 {{$t("constants.closing")}}
        img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px").mb-5

      .col-12.mt-5(v-if="bookingTicket && !bookingTicket.DatesCancelRequestedAt && !bookingTicket.ThirdPartyBookingId")
        .float-right
          button.button.button-secondary.button-td-grey(@click="requestCancelBookingTicket") Stornierung anfragen
</template>

<script>
import EventBus from "../../event-bus";
import { BookingTicketStatusEnum } from "../../utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import FormatDateComponent from "../../components/formatDate/FormatDateComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import { BookingTicketsComponent } from "@/components/bookingTickets/BookingTicketsComponent";

const moment = require("moment");

export default {
  name: "booking_confirmed",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTickets: {
      type: Array,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      moment: moment,
      formatDateComponent: FormatDateComponent,
      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  computed: {
    nights() {
      try {
        if (!this.product) {
          return undefined;
        }
        const nights = this.product.Uebernachtungen;
        if (nights == undefined) {
          return undefined;
        }
        return nights;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    bookingTicket() {
      try {
        if (!this.bookingTickets) {
          return undefined;
        }
        const bookingTickets = this.bookingTickets;
        if (!bookingTickets) {
          return undefined;
        }
        const bookedBookingTicket = bookingTickets.find(
          (t) => t.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked
        );
        if (!bookedBookingTicket) {
          return undefined;
        }
        return bookedBookingTicket;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
  },
  methods: {
    async requestCancelBookingTicket() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die Anfrage für die Stornierung Ihrer Buchung absenden wollen?"
        ).then((e) => e);
        if (!confirmed) {
          return;
        }
        const bookingTicketsComponent = new BookingTicketsComponent();
        EventBus.$emit("changeLoadingState", true);
        const requestedCancelBookingTicket =
          await bookingTicketsComponent.requestCancelBookingTicket(
            this.$apollo,
            this.bookingTicket.Id
          );
        EventBus.$emit("changeLoadingState", false);
        if (!requestedCancelBookingTicket) {
          return this.$alert(
            "Die Stornoanfrage konnte nicht versendet werden."
          );
        }
        if (requestedCancelBookingTicket.Message) {
          this.$alert(requestedCancelBookingTicket.Message);
        }
        if (requestedCancelBookingTicket.IsSuccess) {
          this.loadVoucher();
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
