<template lang="pug">
.login-wrapper
  .login-position
    .container-fluid
      .row
        .col-12.col-md-10.offset-md-1.col-lg-8.offset-lg-2.col-xl-6.offset-xl-3.bg-white.px-4.py-5
          .row
            .col-12.text-center.mb-4
              img.login-logo(:src="`${publicPath}images/touribook-grey-colored-basic-250.png`" alt="")
            .col-12.text-center.mb-3
              h3.txt-light {{$t("login.headline")}}
          .row.text-center(style="font-weight: 700").mb-4
            .col-xs-12.col-sm-4
              span.align-self-center
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{$t("login.check1")}}
            .col-xs-12.col-sm-4
              span
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{$t("login.check2")}}
            .col-xs-12.col-sm-4
              span
                img(:src="`${publicPath}images/tbook-check.png`")
                | {{$t("login.check3")}}
          hr
          .row.mt-4
            .col-xs-12.col-md-6
              .form-group
                label(for="inputVoucherNo", style="font-weight: 700") {{$t("login.vouchernumber")}}
                input#inputVoucherNo.form-control(type="text" v-model="voucherId" :placeholder="this.$t('login.vouchernumber-placeholder')" required="" autofocus="")
            .col-xs-12.col-md-6
              .form-group
                label(for="inputBookingCode", style="font-weight: 700") {{$t("login.bookingcode")}}
                input#inputBookingCode.form-control(type="password" v-model="code" :placeholder="this.$t('login.bookingcode-placeholder')" required="" v-on:keyup.enter="login")
            .col-12.mt-3
              .form-group.form-check.text-muted
                input#acceptedTermins.form-check-input(type="checkbox" v-model="acceptedTerms")
                label.form-check-label(for="acceptedTermins") {{$t("login.permission1")}}
                  a(href="https://www.touridat.com/agb") {{$t("login.permission2")}}
                  | {{$t("login.permission3")}}
                  a(href="https://www.touridat.com/datenschutz") {{$t("login.permission4")}}
                  | {{$t("login.permission5")}}
            .col-12.text-right
              .form-group
                button.button.button-primary.button-tbook(type="submit" @click="login") {{$t("buttons.login")}}
          hr
          .row
            .col-12.text-center
              p.mt-3.mb-3.text-muted &copy; 2021 | touriDat GmbH & Co. KG |&nbsp;
                a(href="https://www.touridat.com/impressum") Impressum
            .col-12.text-center
              img(:src="`${publicPath}images/flag_ger.jpg`", style="height: 20px; cursor: pointer;", @click="changeLanguage('de')").pr-2
              img(:src="`${publicPath}images/flag_gb.jpg`", style="height: 20px; cursor: pointer;", @click="changeLanguage('en')").pl-2
</template>

<script>
import auth from "../../utils/authentification.utils";
import EventBus from "../../event-bus";
import { Vouchers } from "@/graphql/vouchers/Vouchers.ts";

const moment = require("moment");

export default {
  name: "Login",
  data() {
    return {
      voucherId: "",
      code: "",
      acceptedTerms: false,
      publicPath: process.env.BASE_URL,
    };
  },
  mounted() {
    try {
      if (!this.$route.query.auth) {
        return;
      }
      this.$apollo
        .query({
          query: Vouchers.Queries.VoucherByVoucherAuthentificationUrl,
          variables: {
            guid: this.$route.query.auth,
          },
        })
        .then(({ data }) => {
          if (!data || !data.voucherByVoucherAuthentificationUrl) {
            return;
          }
          this.$session.start();
          this.$session.set(
            "voucherId",
            data.voucherByVoucherAuthentificationUrl.Id
          );
          this.$session.set(
            "validTill",
            moment(new Date()).add(12, "hours").toDate()
          );
          this.$router.push({ name: "home" });
        });
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    login() {
      try {
        if (!this.voucherId) {
          return this.$alert(this.$t("login.alerts.vouchernumber").toString());
        }
        if (isNaN(this.voucherId)) {
          return this.$alert(
            this.$t("login.alerts.vouchernumberNoNumber").toString()
          );
        }
        if (!this.code) {
          return this.$alert(this.$t("login.alerts.bookingcode").toString());
        }
        if (!this.acceptedTerms) {
          return this.$alert(this.$t("login.alerts.read-gtc").toString());
        }
        EventBus.$emit("changeLoadingState", true);
        auth.login(this.$apollo, this.voucherId, this.code, (result) => {
          EventBus.$emit("changeLoadingState", false);
          if (result.Message) {
            this.$alert(result.Message);
          }
          if (result.Authenticated && result.VoucherId) {
            this.$session.start();
            this.$session.set("voucherId", result.VoucherId);
            this.$session.set(
              "validTill",
              moment(new Date()).add(12, "hours").toDate()
            );
            this.$router.push({ path: `/bookmyvoucher/` });
          }
        });
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
    changeLanguage(lang) {
      try {
        this.$i18n.locale = lang;
        localStorage.setItem("localeSettings", lang);
      } catch (e) {
        localStorage.setItem("localeSettings", "de");
      }
    },
  },
};
</script>

<style scoped lang="scss">
html {
  height: 100%;
}

#login {
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: space-around;
  -webkit-justify-content: space-around;
  padding-top: 40px;
  padding-bottom: 40px;
  background-color: #f5f5f5;
}

.form-signin {
  width: 100%;
  max-width: 330px;
  padding: 15px;
  margin: auto;

  .checkbox {
    font-weight: 400;
  }

  .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;

    &:focus {
      z-index: 2;
    }
  }

  input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
}
</style>
