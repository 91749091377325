import gql from "graphql-tag";

export const OpenGds = {
    Mutations: {
        CreateReservation: gql`
        mutation createReservation($voucherId: ID, $bookingDate: Date, $bookingRequest: BookingRequestsInput) {
            createReservation(voucherId: $voucherId, bookingDate: $bookingDate, bookingRequest: $bookingRequest) {
                IsSuccess
                Message
            }
        }`,
    }
};
