import gql from "graphql-tag";

export const HostImages = {
    Queries: {
        HostImages: gql`
        query hostImages($hostId: ID) {
            hostImages(hostId: $hostId) {
                Id
                Guid
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                Position
                RemoteBaseUrl
                HtmlImageBlur
                HostId
                ImageId
                Image {
                    ImageName
                    FileName
                    ImageTypeId
                }
            }
        }`,
        HostImagesByProductId: gql`
        query hostImagesByProductId($productId: ID) {
            hostImagesByProductId(productId: $productId) {
                Id
                Guid
                CreatedAt
                CreatedBy
                UpdatedAt
                UpdatedBy
                Position
                RemoteBaseUrl
                HtmlImageBlur
                HostId
                ImageId
                Image {
                    ImageName
                    FileName
                    ImageTypeId
                }
            }
        }`,
    },
};
