const { Products } = require("@/graphql/products/Products.ts");

class ProductsComponent {
  async getProduct(apollo, productId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!productId) {
        return undefined;
      }
      const product = await apollo
        .query({
          query: Products.Queries.Product,
          fetchPolicy: "no-cache",
          variables: {
            id: productId,
          },
        })
        .then(({ data }) => data?.product)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!product) {
        return undefined;
      }
      return product;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = ProductsComponent;
