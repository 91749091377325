import gql from "graphql-tag";

export const ExclusionPeriods = {
    Queries: {
        ExclusionPeriods: gql`
        query exclusionPeriods($voucherId: ID, $from: Date, $to: Date) {
            exclusionPeriods(voucherId: $voucherId, from: $from, to: $to) {
                ExclusionDays
                ExclusionSpans {
                    from
                    to
                }
                ExclusionWeekDays
            }
        }`,
    }
}
