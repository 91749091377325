<template lang="pug">
#bookingDates
  .content-wrapper
    .row.mt-5.text-center
      .col-12
        .background-circle
          .fa-4x(style="color:#fff").align-self-center
            font-awesome-icon(:icon="['fas', 'calendar-alt']")
      .col-12.mt-3
        h2 {{$t("booking-can-be-booked.headline")}}
      .col-12
        h4 {{$t("constants.headline")}} {{this.voucher.Id}}
    .row.mt-5
      .col-12
        .table-responsive
          table.table.table-borderless.d-table.border(v-if="bookingTicket")
            thead.table-head
              tr
                th(scope="col")
                  b {{$t("constants.ticketNumber")}} &nbsp;
                    | {{ bookingTicket.Id }}
                th(scope="col")
                  b {{$t("constants.requestedOn")}}&nbsp;
                    | {{ bookingTicket.CreatedAt | formatDate }}
                th(scope="col")
            tbody
              tr(style="line-height: 20px;")
                td
                  b {{$t("constants.travelPeriod")}}
                td
                  b {{$t("constants.status")}}
                td
              template(v-for="bookingDateRequest in bookingDateRequests")
                tr
                  td {{ bookingDateRequest.Date | formatDate }}  {{$t("constants.until")}}  {{ moment(bookingDateRequest.Date).add(product.Uebernachtungen, "days").toDate() | formatDate }}
                  td(v-if="bookingDateRequest.BookingDateRequestStatusId == 1") {{$t("booking-requested.status")}}
                  template(v-else-if="bookingDateRequest.BookingDateRequestStatusId == 2")
                    td(style="font-weight:700; color: #1b3f68") {{$t("booking-can-be-booked.status")}}
                    td
                      .button.button-primary.button-tbook(@click="saveBookingDateRequest(bookingDateRequest.Id)") {{$t("booking-can-be-booked.button")}}
                  td(v-else-if="bookingDateRequest.BookingDateRequestStatusId == 3") {{$t("booking-confirmed.status")}}
                  td(v-else-if="bookingDateRequest.BookingDateRequestStatusId == 4") {{$t("booking-rejected.status")}}
                  td(v-else-if="bookingDateRequest.BookingDateRequestStatusId == 5") {{$t("booking-canceled.status")}}

    .row.mt-5(v-if="bookingRequest")
      .col-12
        p(v-if="bookingRequest && bookingRequest.Title == 'Herr'") {{$t("constants.AddressM")}} {{bookingRequest.LastName}},
        p(v-else) {{$t("constants.AddressF")}} {{bookingRequest.LastName}},
        p {{$t("booking-can-be-booked.text1")}}
        p {{$t("booking-can-be-booked.text2")}}
        p.mb-0 {{$t("constants.closing")}}
        img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px")

</template>

<script>
import EventBus from "../../event-bus";
import { BookingDateRequests } from "@/graphql/bookingDateRequests/BookingDateRequests.ts";
import BookingDataComponent from "@/components/bookingData/BookingDataComponent";

const moment = require("moment");

export default {
  name: "booking_can_be_booked",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    bookingTicket: {
      type: Object,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      bookingDateRequests: [],
      publicPath: process.env.BASE_URL,
      moment: moment,
    };
  },
  async mounted() {
    try {
      this.loadBookingDateRequests();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadBookingDateRequests() {
      try {
        this.$apollo
          .query({
            query:
              BookingDateRequests.Queries.BookingDateRequestsByBookingTicketId,
            fetchPolicy: "no-cache",
            variables: {
              bookingTicketId: this.bookingTicket.Id,
            },
          })
          .then(({ data }) => {
            if (!data || !data.bookingDateRequestsByBookingTicketId) {
              return;
            }
            this.bookingDateRequests =
              data.bookingDateRequestsByBookingTicketId;
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async saveBookingDateRequest(bookingDateRequestId) {
      try {
        if (!bookingDateRequestId) {
          return this.$alert("Keine Buchungsdatumsanfrage gefunden.");
        }
        const bookingDataComponent = new BookingDataComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedAcceptedBookingDateRequest =
          await bookingDataComponent.saveAcceptedBookingDateRequest(
            this.$apollo,
            bookingDateRequestId
          );
        EventBus.$emit("changeLoadingState", false);
        if (!savedAcceptedBookingDateRequest) {
          return this.$alert(
            "Ihre Buchungsdatumsanfrage konnte nicht gespeichert werden."
          );
        }
        if (savedAcceptedBookingDateRequest.Message) {
          this.$alert(savedAcceptedBookingDateRequest.Message);
        }
        if (savedAcceptedBookingDateRequest.IsSuccess) {
          EventBus.$emit("changeBookingState");
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
