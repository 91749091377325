const VoucherStatusEnum = {
  Created: 1,
  Sent: 2,
  Cancelled: 3,
  Redeemed: 4,
  Booked: 5,
  TouriDaysRefund: 7,
  HostCancel: 8,
};

module.exports = VoucherStatusEnum;
