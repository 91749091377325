<template lang="pug">
.container-fluid
  .text-center
    .row
      .col-12.mt-2
        .float-left
          button.button.button-primary.button-tpics(@click="openReportErrorModal" :disabled="!voucher" :class="{ 'disabled': !voucher }") Fehler melden
        .float-right
          button.button.button-primary.button-tbook(@click="loadVoucher(); loadBookingRequest;")
            font-awesome-icon(icon="fa-solid fa-rotate-right")

      .col-12(v-if="host")
        template(v-if="bookingTicket")
          BookingRequested(v-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested" :voucherId="voucherId")
          booking-can-be-booked(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCanBeBooked" :voucher="voucher" :product="product" :booking-request="bookingRequest" :booking-ticket="bookingTicket" :load-voucher="loadVoucher")
          booking-confirmed(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesBooked" :voucher="voucher" :product="product" :booking-request="bookingRequest" :host="host" :booking-tickets="bookingTickets" :load-voucher="loadVoucher")
          customer-data(:voucher="voucher" :booking-request="bookingRequest" :host="host" :load-voucher="loadVoucher" v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesRejected || bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCanceled")
          BookingCancelRequest(v-else-if="bookingTicket.BookingTicketStatusId == BookingTicketStatusEnum.DatesCancelRequested" :voucherId="voucherId")
        customer-data(v-else-if="voucher" :voucher="voucher" :booking-request="bookingRequest" :host="host" :load-voucher="loadVoucher")
        router-view.mt-3
</template>

<script>
import EventBus from "@/event-bus";
import { BookingTicketStatusEnum } from "../../utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { Vouchers } from "@/graphql/vouchers/Vouchers.ts";
import { BookingRequests } from "@/graphql/bookingRequests/BookingRequests.ts";
import { BookingTickets } from "@/graphql/bookingTickets/BookingTickets.ts";
import ProductsComponent from "@/components/products/ProductsComponent";
import HostsComponent from "@/components/hosts/HostsComponent";
import ReportError from "@/vue/modals/report-error.vue";

export default {
  name: "home",
  data() {
    return {
      voucherId: this.$session.get("voucherId"),
      voucher: undefined,
      product: undefined,
      host: undefined,
      bookingRequest: {
        Title: "Herr",
        Country: "DEU",
        VoucherId: this.$session.get("voucherId"),
      },
      bookingTickets: [],

      BookingTicketStatusEnum: BookingTicketStatusEnum,
    };
  },
  mounted() {
    EventBus.$on(
      "changeBookingState",
      function () {
        this.loadVoucher();
      }.bind(this)
    );
    this.loadVoucher();
  },
  computed: {
    bookingTicket() {
      try {
        if (!this.bookingTickets) {
          return undefined;
        }
        if (this.bookingTickets.length == 0) {
          return undefined;
        }
        const bookingTicket = this.bookingTickets.find(
          (b) =>
            b.BookingTicketStatusId != BookingTicketStatusEnum.DatesRejected &&
            b.BookingTicketStatusId != BookingTicketStatusEnum.DatesCanceled
        );
        if (!bookingTicket) {
          return undefined;
        }
        return bookingTicket;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
  },
  methods: {
    async loadVoucher() {
      try {
        EventBus.$emit("changeLoadingState", true);
        const voucher = await this.$apollo
          .query({
            query: Vouchers.Queries.Voucher,
            fetchPolicy: "no-cache",
            variables: {
              id: this.voucherId,
            },
          })
          .then(({ data }) => data?.voucher)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        EventBus.$emit("changeLoadingState", false);
        if (!voucher) {
          return this.$alert("Es konnte kein Gutschein geladen werden.");
        }
        this.voucher = voucher;
        this.loadBookingRequest();
        this.loadProduct();
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadBookingRequest() {
      try {
        const bookingRequest = await this.$apollo
          .query({
            query: BookingRequests.Queries.BookingRequestByVoucherId,
            fetchPolicy: "no-cache",
            variables: {
              voucherId: this.voucherId,
            },
          })
          .then(({ data }) => data?.bookingRequestByVoucherId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!bookingRequest) {
          return;
        }
        this.bookingRequest = bookingRequest;
        this.loadBookingTickets();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingTickets() {
      try {
        const bookingTickets = await this.$apollo
          .query({
            query: BookingTickets.Queries.BookingTicketsByBookingRequestId,
            fetchPolicy: "no-cache",
            variables: {
              bookingRequestId: this.bookingRequest.Id,
            },
          })
          .then(({ data }) => data?.bookingTicketsByBookingRequestId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!bookingTickets) {
          return;
        }
        this.bookingTickets = bookingTickets;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadProduct() {
      try {
        const productsComponent = new ProductsComponent();
        const product = await productsComponent.getProduct(
          this.$apollo,
          this.voucher.ProductId
        );
        if (!product) {
          return;
        }
        this.product = product;
        this.loadHost();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadHost() {
      try {
        const hostsComponent = new HostsComponent();
        const host = await hostsComponent.getHost(
          this.$apollo,
          this.product.HostId
        );
        if (!host) {
          return;
        }
        this.host = host;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openReportErrorModal() {
      try {
        this.$modal.show(
          ReportError,
          {
            voucherId: this.voucher.Id,
          },
          {
            scrollable: true,
            resizable: true,
            height: "auto",
            width: "80%",
          }
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
