import gql from "graphql-tag";

export const BookingTicketConversations = {
    Queries: {
        BookingTicketConversation: gql`
        query bookingTicketConversation($bookingTicketId: ID) {
            bookingTicketConversation(bookingTicketId: $bookingTicketId) {
                Id
                Guid
                CreatedAt
            }
        }`,
    }
};
