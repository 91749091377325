import Home from "@/vue/pages/home";
import Login from "@/vue/pages/login";
import Customer from "@/vue/components/customer_data";
import BookingRequested from "@/vue/components/booking_requested";
import BookingConfirmed from "@/vue/components/booking_confirmed";
import NotFound from "@/vue/pages/not_found";

export default [
  {
    path: "/login",
    component: Login,
    name: Login.name,
    meta: {
      isLogin: true,
    },
  },
  {
    path: "/",
    component: Home,
    name: Home.name,
  },
  {
    path: "/bookmyvoucher",
    component: Home,
    name: Home.name,
    children: [
      {
        path: "booking-dates",
        component: BookingRequested,
        name: BookingRequested.name,
      },
      {
        path: "customer",
        component: Customer,
        name: Customer.name,
      },
      {
        path: "booking-confirmed",
        component: BookingConfirmed,
        name: BookingConfirmed.name,
      },
    ],
  },
  {
    path: "/*",
    component: NotFound,
    name: NotFound.name,
    meta: {
      PlainTemplate: true,
    },
  },
];
