<template lang="pug">
.datepicker
  td-loading-overlay(
    v-if="isLoading"
    position="absolute"
  )
  datepicker(
  :inline="true"
  :bootstrap-styling="true"
  :language="lang"
  :monday-first="true"
  :disabled-dates="disabledSpans"
  :highlighted="disabledSpans"
  @selected="selectDate($event)"
  @changedMonth="changeDate($event)"
  @changedYear="changeDate($event)"
  @changedDecade="changeDate($event)"
)
</template>

<script>
import Datepicker from "vuejs-datepicker";
import TdLoadingOverlay from "../../partials/loading/td-loading-overlay.vue";
import EventBus from "../../../event-bus";

export default {
  name: "BookingDatePicker",
  components: {
    TdLoadingOverlay,
    Datepicker,
  },
  props: {
    lang: {
      type: [String, Object],
      required: true,
    },
    disabledSpans: {
      type: Object,
      required: true,
    },
    selectDate: {
      type: Function,
      required: true,
    },
    changeDate: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
        "changeCalendarLoading",
        function (isLoading) {
          this.isLoading = isLoading;
        }.bind(this)
      );
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
};
</script>

<style scoped></style>
