<template lang="pug">
#bookingDates
  .content-wrapper
    .row.mt-5.text-center
      .col-12
        .background-circle
          .fa-4x(style="color:#fff").align-self-center
            font-awesome-icon(:icon="['fas', 'paper-plane']", style="padding-right: 10px;")
      .col-12.mt-3
        h2 {{$t("booking-requested.headline")}}
      .col-12
        h4 {{$t("constants.headline")}} {{this.voucherId}}
    .row.mt-5
      .col-12
        .table-responsive(v-if="bookingTicket")
          table.table.table-borderless.d-table.border
            thead.table-head
              tr
                th(scope="col")
                  b {{$t("constants.ticketNumber")}}&nbsp;
                    | {{bookingTicket.Id}}
                th(scope="col")
                  b {{$t("constants.requestedOn")}}&nbsp;
                    | {{ bookingTicket.CreatedAt | formatDate }}
                th(scope="col")
            tbody(v-if="bookingDateRequests && bookingDateRequests.length > 0")
              tr(style="line-height: 20px;")
                td
                  b {{$t("constants.travelPeriod")}}
                td
                  b {{$t("constants.status")}}
                td
              template(v-for="bookingDateRequest in bookingDateRequests")
                tr
                  td {{ bookingDateRequest.Date | formatDate }} {{$t("constants.until")}} {{ moment(bookingDateRequest.Date).add(nights, "days") | formatDate }}
                  td(style="font-weight:700; color: #777777") {{$t("booking-requested.status")}}

    .row.mt-5(v-if="bookingRequest")
      .col-12
        p(v-if="bookingRequest && bookingRequest.Title == 'Herr'") {{$t("constants.AddressM")}} {{bookingRequest.LastName}},
        p(v-else) {{$t("constants.AddressF")}} {{bookingRequest.LastName}},
        p {{$t("booking-requested.text")}}
        p.mb-0 {{$t("constants.closing")}}
        img(:src='$t("constants.touriBookTeam", {publicPath: publicPath})', style="height: 30px")

</template>

<script>
import { BookingTicketStatusEnum } from "../../utils/enums/bookingTicketStatus/BookingTicketStatusEnum";
import { Vouchers } from "@/graphql/vouchers/Vouchers.ts";
import { BookingRequests } from "@/graphql/bookingRequests/BookingRequests.ts";
import { BookingTickets } from "@/graphql/bookingTickets/BookingTickets.ts";
import { BookingDateRequests } from "@/graphql/bookingDateRequests/BookingDateRequests.ts";
import { Products } from "@/graphql/products/Products.ts";
const moment = require("moment");

export default {
  name: "booking_requested",
  props: {
    voucherId: {
      type: String,
      allowNull: false,
    },
  },
  data() {
    return {
      voucher: undefined,
      bookingRequest: undefined,
      bookingTickets: [],
      bookingDateRequests: [],

      publicPath: process.env.BASE_URL,
      moment: moment,
    };
  },
  computed: {
    nights() {
      try {
        if (!this.product) {
          return undefined;
        }
        const nights = this.product.Uebernachtungen;
        if (nights == undefined) {
          return undefined;
        }
        return nights;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    bookingTicket() {
      try {
        if (!this.bookingTickets) {
          return undefined;
        }
        const requestedBookingTicket = this.bookingTickets.find(
          (t) =>
            t.BookingTicketStatusId == BookingTicketStatusEnum.DatesRequested
        );
        if (!requestedBookingTicket) {
          return undefined;
        }
        return requestedBookingTicket;
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
  },
  mounted() {
    try {
      this.loadVoucher();
      this.loadBookingRequest();
    } catch (e) {
      console.log(e);
      this.$alert(e.message);
    }
  },
  methods: {
    loadVoucher() {
      try {
        this.$apollo
          .query({
            query: Vouchers.Queries.Voucher,
            fetchPolicy: "no-cache",
            variables: {
              id: this.voucherId,
            },
          })
          .then(({ data }) => {
            if (!data || !data.voucher) {
              return this.$alert("Es konnte kein Gutschein geladen werden.");
            }
            this.voucher = data.voucher;
            this.loadProduct();
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadProduct() {
      try {
        this.$apollo
          .query({
            query: Products.Queries.Product,
            fetchPolicy: "no-cache",
            variables: {
              id: this.voucher.ProductId,
            },
          })
          .then(({ data }) => {
            if (!data || !data.product) {
              return;
            }
            this.product = data.product;
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadBookingRequest() {
      try {
        this.$apollo
          .query({
            query: BookingRequests.Queries.BookingRequestByVoucherId,
            fetchPolicy: "no-cache",
            variables: {
              voucherId: this.voucherId,
            },
          })
          .then(({ data }) => {
            if (!data || !data.bookingRequestByVoucherId) {
              return;
            }
            this.bookingRequest = data.bookingRequestByVoucherId;
            this.loadBookingTickets();
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadBookingTickets() {
      try {
        this.$apollo
          .query({
            query: BookingTickets.Queries.BookingTicketsByBookingRequestId,
            fetchPolicy: "no-cache",
            variables: {
              bookingRequestId: this.bookingRequest.Id,
            },
          })
          .then(({ data }) => {
            if (!data || !data.bookingTicketsByBookingRequestId) {
              return;
            }
            this.bookingTickets = data.bookingTicketsByBookingRequestId;
            this.loadBookingDateRequests();
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    loadBookingDateRequests() {
      try {
        this.$apollo
          .query({
            query:
              BookingDateRequests.Queries.BookingDateRequestsByBookingTicketId,
            fetchPolicy: "no-cache",
            variables: {
              bookingTicketId: this.bookingTicket.Id,
            },
          })
          .then(({ data }) => {
            if (!data || !data.bookingDateRequestsByBookingTicketId) {
              return;
            }
            this.bookingDateRequests =
              data.bookingDateRequestsByBookingTicketId;
          })
          .catch((e) => {
            console.log(e);
            this.$alert(e.message);
          });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
