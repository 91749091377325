import gql from "graphql-tag";

export const HyperGuest = {
    Mutations: {
        CreateBooking: gql`
        mutation createBooking($bookingRequest: BookingRequestsInput, $bookingDate: Date) {
            createBooking(bookingRequest: $bookingRequest, bookingDate: $bookingDate) {
                IsSuccess
                Message
            }
        }`,
    },
    Queries: {
        Rate: gql`
        query rate($voucherId: ID, $date: Date) {
            rate(voucherId: $voucherId, date: $date)
        }`,
    }
};
