<template lang="pug">
#voucherConversation
  h5.messenger-head.m-0 {{$t("booking-rejected.MessageHistory")}}
  .content-wrapper
    .row
      .col-12
        div.w-100(v-if="bookingTicketConversation" style="border: 1px solid #c2c2c2!important; background-color:#CCDBED")
          .pt-4.pb-4.pl-2.pr-2
            template(v-for="bookingTicketConversationMessage in bookingTicketConversationMessages")
              .text-center(v-if="bookingTicketConversationMessage.BookingTicketConversationMessageTypeId == BookingTicketConversationMessageTypesEnum.System").mb-2
                p(style="font-size:10px; color:#777777; margin-bottom: 0") {{ bookingTicketConversationMessage.CreatedAt | formatDateTime }}
                p(style="margin-bottom: 20px;") {{bookingTicketConversationMessage.Message}}
              .box-left.w-75(v-else-if="bookingTicketConversationMessage.BookingTicketConversationMessageTypeId == BookingTicketConversationMessageTypesEnum.Guest")
                p(style="font-size:10px; color:#777777; padding-left: 5px; margin-bottom: 0;") {{ bookingTicketConversationMessage.CreatedAt | formatDateTime }}
                .bubble-right.triangle-left.w-100 {{bookingTicketConversationMessage.Message}}
              .box-right.w-75(v-else-if="bookingTicketConversationMessage.BookingTicketConversationMessageTypeId == BookingTicketConversationMessageTypesEnum.Host").w-75
                p(style="font-size:10px; color:#777777; padding-left: 5px; margin-bottom: 0") {{ bookingTicketConversationMessage.CreatedAt | formatDateTime }}
                .bubble-left.triangle-right.w-100 {{bookingTicketConversationMessage.Message}}

      .col-12
        .input-group
          textarea-autosize.form-control(:placeholder="this.$t('booking-ticket-conversation.placeholder')" v-model="message")
          .input-group-append
            button.button.button-primary.button-tbook(@click="createBookingTicketConversationMessage") {{$t("booking-ticket-conversation.button")}}

</template>

<script>
import { BookingTicketConversationMessageTypesEnum } from "../../../utils/enums/bookingTicketConversationMessageTypes/BookingTicketConversationMessageTypesEnum";
import EventBus from "../../../event-bus";
import { BookingTicketConversations } from "@/graphql/bookingTicketConversations/BookingTicketConversations.ts";
import { BookingTicketConversationMessages } from "@/graphql/bookingTicketConversationMessages/BookingTicketConversationMessages.ts";

export default {
  name: "booking_ticket_conversation",
  props: {
    bookingTicketId: {
      type: Number,
      allowNull: false,
    },
  },
  data() {
    return {
      message: "",
      bookingTicketConversation: {},
      bookingTicketConversationMessages: [],
      BookingTicketConversationMessageTypesEnum:
        BookingTicketConversationMessageTypesEnum,
    };
  },
  async mounted() {
    try {
      await this.loadBookingTicketConversation();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadBookingTicketConversation() {
      try {
        const bookingTicketConversation = await this.$apollo
          .query({
            query: BookingTicketConversations.Queries.BookingTicketConversation,
            variables: {
              bookingTicketId: this.bookingTicketId,
            },
          })
          .then(({ data }) => data?.bookingTicketConversation)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!bookingTicketConversation) {
          return;
        }
        this.bookingTicketConversation = bookingTicketConversation;
        await this.loadBookingTicketConversationMessages();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadBookingTicketConversationMessages() {
      try {
        const bookingTicketConversationMessages = await this.$apollo
          .query({
            query:
              BookingTicketConversationMessages.Queries
                .BookingTicketConversationMessages,
            fetchPolicy: "no-cache",
            variables: {
              bookingTicketConversationId: this.bookingTicketConversation.Id,
            },
          })
          .then(({ data }) => data?.bookingTicketConversationMessages)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!bookingTicketConversationMessages) {
          return;
        }
        this.bookingTicketConversationMessages =
          bookingTicketConversationMessages;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async createBookingTicketConversationMessage() {
      try {
        const bookingTicketConversationMessage = {
          Message: this.message,
          BookingTicketConversationMessageTypeId:
            this.BookingTicketConversationMessageTypesEnum.Guest,
        };
        EventBus.$emit("changeLoadingState", true);
        const createdBookingTicketConversationMessage = await this.$apollo
          .mutate({
            mutation:
              BookingTicketConversationMessages.Mutations
                .CreateBookingTicketConversationMessage,
            fetchPolicy: "no-cache",
            variables: {
              bookingTicketConversationMessage:
                bookingTicketConversationMessage,
              bookingTicketId: this.bookingTicketId,
            },
          })
          .then(({ data }) => data?.createBookingTicketConversationMessage)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        EventBus.$emit("changeLoadingState", false);
        if (!createdBookingTicketConversationMessage) {
          return this.$alert("Die Nachricht konnte nicht gesendet werden.");
        }
        this.message = "";
        await this.loadBookingTicketConversationMessages();
      } catch (e) {
        EventBus.$emit("changeLoadingState", false);
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
