import { BookingTickets } from "../../graphql/bookingTickets/BookingTickets.ts";

export class BookingTicketsComponent {
  async requestCancelBookingTicket(apollo, bookingTicketId) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!bookingTicketId) {
        return {
          IsSuccess: false,
          Message: "Keine Buchungsticket-Id gefunden.",
        };
      }
      const requestedCancelBookingTicket = await apollo
        .mutate({
          mutation: BookingTickets.Mutations.RequestCancelBookingTicket,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicketId,
          },
        })
        .then(({ data }) => data?.requestCancelBookingTicket)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      if (!requestedCancelBookingTicket) {
        return {
          IsSuccess: false,
          Message: "Die Stornoanfrage konnte nicht versendet werden.",
        };
      }
      return requestedCancelBookingTicket;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}
