<template lang="pug">
.modal-wrap.p-3
  .modal-close-wrap
    i.modal-close-button(@click="close")
      font-awesome-icon(:icon="['fas', 'times']")

  .container-fluid#help-modal
    .row
      .col-12
        .modal-headline.pb-2.mb-3.border-bottom {{$t("help-modal.headline")}}
    .row
      .col-12
        p {{$t("help-modal.text")}}
      .col-12
        i
          font-awesome-icon(:icon="['fas', 'envelope']")
          h6.d-inline-block(style="padding-left:5px") {{$t("help-modal.emailHeadline")}}
        p {{$t("help-modal.emailText1")}}
          | &nbsp;
          a(href="https://www.touridat.com/impressum") support@touribook.de&nbsp;
          br
          | {{$t("help-modal.emailText2")}}
      .col-12
        i
          font-awesome-icon(:icon="['fas', 'comments']")
          h6.d-inline-block(style="padding-left:5px") {{$t("help-modal.chatHeadline")}}
        p {{$t("help-modal.chatText1")}}
          | und&nbsp;
          a(href="https://www.touridat.com/impressum") {{$t("help-modal.chatText2")}}
      .col-12
        i
          font-awesome-icon(:icon="['fas', 'phone-alt']")
          h6.d-inline-block(style="padding-left:5px") {{$t("help-modal.phoneHeadline")}}
        p {{$t("help-modal.phoneText")}}


</template>

<script>
export default {
  name: "help-modal",
  props: {
    viewDescription: String,
  },
  data() {
    return {
      showFirst: true,
      showSecond: false,
      showThrid: false,
    };
  },
  methods: {
    showFirstPic() {
      this.showFirst = true;
      this.showSecond = false;
      this.showThird = false;
    },
    showSecondPic() {
      this.showSecond = true;
      this.showFirst = false;
      this.showThird = false;
    },
    showThirdPic() {
      this.showThird = true;
      this.showFirst = false;
      this.showSecond = false;
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style scoped>
#help-modal ul {
  padding-left: 0;
  list-style-type: none;
}

#help-modal ul li {
  cursor: pointer;
  font-weight: 500;
  margin-bottom: 8px;
}

#help-modal ul li:hover {
  color: #488345;
}
</style>
