import gql from "graphql-tag";

export const LoginVoucherMutation = gql`
  mutation LoginVoucher($id: ID, $code: String) {
    LoginVoucher(id: $id, code: $code) {
      Authenticated
      Token
      Message
      VoucherId
    }
  }
`;
