export const BookingTicketEmailLogEmailsEnum = {
    HostReminderEmail: 1,
    HostNotReactingEmail: 2,
    HostVoucherCancelledEmail: 3,
    HostBookingDatesBookedEmail: 4,
    CustomerVoucherCancelledEmail: 5,
    HostBookingDatesRequestedEmail: 6,
    CustomerBookingDatesBookedEmail: 7,
    HostCanBeBookedDatesExpiredEmail: 8,
    CustomerBookingDatesRejectedEmail: 9,
    CustomerBookingDatesRequestedEmail: 10,
    CustomerBookingDatesCancelledEmail: 11,
    HostBookingDatesCancelRequestEmail: 12,
    CustomerBookingDatesCanBeBookedEmail: 13,
    CustomerCanBeBookedDatesExpiredEmail: 14,
    CustomerBookingDatesAcceptedCancelEmail: 15,
    CustomerBookingDatesDeclinedCancelEmail: 16,
    CustomerDatesCanBeBookedReminderEmail: 17,
};
