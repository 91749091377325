<template lang="pug">
#overlay(:style="'position: ' + position")
  #overlayText(:style="'position: ' + position")
    #td-loading-wrapper
      #td-loading-gradient
        #svg-position
          object(data="https://d1xkfcniu0czj4.cloudfront.net/testing/img/tD-loading.svg" type="image/svg+xml")
            <!-- Fallback -->
            | Ihr Browser kann leider keine svg darstellen!

</template>

<script>
export default {
  name: "td-loading-overlay",
  props: {
    position: {
      type: String,
      required: false,
      default: "fixed",
    },
  },
};
</script>

<style scoped>
#overlay {
  display: block;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

#overlayText {
  top: 50%;
  left: 50%;
  font-size: 50px;
  color: white;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}

#td-loading-gradient {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 100%;
  background: linear-gradient(269deg, #bb6a00, #7a1313, #1b3f68, #1a4e17);
  background-size: 800% 800%;
  -webkit-animation: tD-loading-gradient 2s ease infinite;
  -moz-animation: tD-loading-gradient 2s ease infinite;
  -o-animation: tD-loading-gradient 2s ease infinite;
  animation: tD-loading-gradient 2s ease infinite;
}
</style>
