import gql from "graphql-tag";

export const AddressCountries = {
    Queries: {
        AddressCountries: gql`
        query addressCountries {
            addressCountries {
                Id
                Guid
                CountryEN
                CountryDE
                ISO31661alpha3
                ISO31661alpha2
            }
        }`,
    },
}
