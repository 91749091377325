import VModal from "vue-js-modal";
import VueSimpleAlert from "vue-simple-alert";
import multiselect from "vue-multiselect";

export const vuePluginsUtils = [
  {
    plugin: VModal,
    config: {},
  },
  {
    plugin: VueSimpleAlert,
    config: {
      customClass: {
        confirmButton: "button button-primary button-tbook",
        cancelButton: "button button-primary button-tpics ml-3",
        popup: "alert-popup",
      },
      cancelButtonText: "Abbrechen",
      buttonsStyling: false,
    },
  },
  {
    plugin: multiselect,
    config: {},
  },
];
