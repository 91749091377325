import gql from "graphql-tag";

export const Hosts = {
    Queries: {
        Host: gql`
        query host($id: ID) {
            host(id: $id) {
                Id
                Match1
                Match2
                PAdresse1
                PAdresse2
                PAdresse3
                PAdresse4
                PLAND
                PPLZ
                PORT
                RAdresse1
                RAdresse2
                RAdresse3
                RAdresse4
                RAdresse5
                RAdresse6
                RAdresse7
                RLAND
                RPLZ
                RORT
                Ansprechpartner
                TELEFON1
                TELEFON2
                TELEFAX1
                TELEFAX2
                EMAIL1
                EMAIL2
                Internetadresse1
                Internetadresse2
                PASSWD
                ProvisionFP
                Verkaufsgebuehren
                Gesperrt_Bis
                CreatedAt
                Stars
                CityRegion
                ThirdPartyId
                Description
                Name
                Longitude
                Latitude
                AccountOwner
                IBAN        
                HTMLTitel
                HTMLSubtitel
                HTMLBlock01
                HTMLBlock02
                HTMLBlock03
                HTMLBlock04        
                LastOpenedBy
                LastOpenedAt
                InsolventSince
                PropertyId
                ChannelPropertyTypeId
            }
        }`,
    },
};
