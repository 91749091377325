const { OpenGds } = require("@/graphql/openGds/OpenGds.ts");

class OpenGdsComponent {
  async createReservation(apollo, bookingDate, bookingRequest) {
    try {
      if (!apollo) {
        return {
          IsSuccess: false,
          Message: "Kein Apollo-Client gefunden.",
        };
      }
      if (!bookingDate) {
        return {
          IsSuccess: false,
          Message: "Kein Buchungsdatum gefunden.",
        };
      }
      if (!bookingRequest) {
        return {
          IsSuccess: false,
          Message: "Keine Buchungsanfrage gefunden.",
        };
      }
      const createdReservation = await apollo
        .mutate({
          mutation: OpenGds.Mutations.CreateReservation,
          fetchPolicy: "no-cache",
          variables: {
            bookingDate: bookingDate,
            bookingRequest: bookingRequest,
          },
        })
        .then(({ data }) => data?.createReservation)
        .catch((e) => {
          console.error(e);
          return {
            IsSuccess: false,
            Message: e.message,
          };
        });
      if (!createdReservation) {
        return {
          IsSuccess: false,
          Message: "Die Reservierung konnte nicht gespeichert werden.",
        };
      }
      return createdReservation;
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}

module.exports = OpenGdsComponent;
