<template lang="pug">
#customer
  .content-wrapper
    .row.mt-5
      .col-12
        .background-circle
          .fa-4x(style="color:#fff").align-self-center
            font-awesome-icon(:icon="['fas', 'calendar-alt']")
      .col-12.mt-3.text-center
        h2 {{$t("customer-data.headline1")}}
      .col-12.text-center
        h4 {{$t("customer-data.headline2")}} {{this.voucher.Id}}

      .col-12.mt-5
        h5 {{$t("customer-data.section-headline1")}}
        p {{$t("customer-data.section-text")}}
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.formOfAddress")}}
          select.custom-select(v-model="bookingRequest.Title")
            option(value="Herr") {{$t("customer-data.gender.m")}}
            option(value="Frau") {{$t("customer-data.gender.f")}}
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.firstName")}}
          input.form-control(:placeholder="this.$t('customer-data.firstNamePlaceholder')" v-model="bookingRequest.FirstName")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.lastName")}}
          input.form-control(:placeholder="this.$t('customer-data.lastNamePlaceholder')" v-model="bookingRequest.LastName")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.streetAndNumber")}}
          input.form-control(:placeholder="this.$t('customer-data.streetAndNumberPlaceholder')" v-model="bookingRequest.Street")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.zipCode")}}
          input.form-control(:placeholder="this.$t('customer-data.zipCodePlaceholder')" v-model="bookingRequest.ZipCode")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.city")}}
          input.form-control(:placeholder="this.$t('customer-data.cityPlaceholder')" v-model="bookingRequest.City")
      .col-12.col-md-4
        label.form-label {{$t("customer-data.country")}}
        select.custom-select(v-model="bookingRequest.Country")
          option(:value="addressCountry.ISO31661alpha3" v-for="addressCountry in addressCountries") {{ addressCountry[$t("customer-data.countryProp")] }}

      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.guestMail")}}
          input.form-control(:placeholder="this.$t('customer-data.guestMailPlaceholder')" v-model="bookingRequest.Email")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.repeat-guestMail")}}
          input.form-control(:placeholder="this.$t('customer-data.repeat-guestMailPlaceholder')" v-model="confirmationEmail")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.phone")}}{{ (host && host.ChannelPropertyTypeId != ChannelPropertyTypesEnum.TouriBook) ? "*" : "" }}
          input.form-control(:placeholder="this.$t('customer-data.phonePlaceholder')" v-model="bookingRequest.Phone")
      .col-12.col-md-4
        .form-group
          label.form-label {{$t("customer-data.dateOfBirth")}}{{ (host && host.ChannelPropertyTypeId != ChannelPropertyTypesEnum.TouriBook) ? "*" : "" }}
          input.form-control(type="date" :placeholder="this.$t('customer-data.datePlaceholder')" :value="bookingRequest.BirthDate | formatInputDate" @change="bookingRequest.BirthDate = $event.target.value;")

      .col-12.mt-4
        h5 {{ $t("customer-data.section-headline2") }}
      .col-12.col-lg-6.mt-3
        img.img-fluid(:src="productImage" v-if="productImage")
        img.img-fluid(v-else :src="`${publicPath}images/bg-image.jpg`" alt="")
      .col-12.col-lg-6.mt-3
        h5 {{ $t("customer-data.arrangement") }}
        p {{ voucher.Arrangement }}

        h5 {{ $t("customer-data.services") }}
        ul
          li(v-for="holidayService in holidayServices" v-if="holidayService.trim()") {{ holidayService.trim() }}

        template(v-if="additionalHolidayServices && Array.isArray(additionalHolidayServices) && additionalHolidayServices.length")
          h5(style="font-style: italic;") {{$t("customer-data.additionalServices")}}
          ul
            li(v-for="additionalHolidayService in additionalHolidayServices" v-if="additionalHolidayService.trim()") {{ additionalHolidayService.trim() }}

        h5 {{ $t("customer-data.bookability") }}
        p {{voucher.BookingAvailability}}

        template(v-if="rate")
          template(v-if="rate.remarks && Array.isArray(rate.remarks) && rate.remarks.length")
            h5.text-danger {{ $t("customer-data.info") }}
            p.text-danger(v-for="remark in rate.remarks") {{ remark }}
          template(v-if="rate.prices && rate.prices.fees && Array.isArray(rate.prices.fees) && rate.prices.fees.length")
            h5.text-danger {{ $t("customer-data.fees") }}
            p.text-danger(v-for="fee in rate.prices.fees") {{ fee.description }}

      .col-12.mt-2
        h5 {{$t("customer-data.section-headline3")}}
        p(v-if="host && host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook") {{$t("customer-data.section-text3")}}

      .col-12.col-lg-6.mt-2
        booking-date-picker(
          :lang="lang"
          :disabled-spans="disabledSpans"
          :change-date="changeDate"
          :select-date="selectDate"
        )

      .col-12.col-lg-6.mt-sm-3
        h5 {{$t("customer-data.desiredDate")}}
        .row.mt-3(v-if="!host.ChannelPropertyTypeId || host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook")
          .col-12(v-for="bookingDate in bookingDates" :class="{ 'mt-3': bookingDates.indexOf(bookingDate) > 0 }")
            .input-group
              .input-group-prepend
                button.button.button-primary.button-tdays(@click="selectedDatePicker = bookingDates.indexOf(bookingDate)")
                  font-awesome-icon(icon="fa-solid fa-check")
              input.form-control(type="date" :placeholder="$t('customer-data.datePlaceholder')" :value="bookingDate | formatInputDate" disabled)
              .input-group-append(v-if="bookingDates.length > 1")
                button.button.button-primary.button-tpics(@click="bookingDates.splice(bookingDates.indexOf(bookingDate), 1)")
                  font-awesome-icon(:icon="['fas','trash']")

          .col-12
            .button.button-secondary.button-tbook.w-100.mt-4(@click="addDate") {{ $t("buttons.alternativeDate") }}
            p.text-muted.mt-2 {{ $t("customer-data.selectDatesInfo") }}
            .form-check.text-muted.mt-5
              input.form-check-input(type="checkbox" v-model="isBindingBooking" id="isBindingBooking")
              label.form-check-label(for="isBindingBooking") {{ $t("customer-data.checkToBook") }}

        .row(v-else)
          .col-12
            input.form-control(type="date" :placeholder="$t('customer-data.datePlaceholder')" :value="bookingDates[0] | formatInputDate" disabled)

      .col-12.text-right.mb-3.mt-sm-3
        button.button.button-primary.button-tbook(@click="saveBookingRequest") {{ $t("buttons.sendDate") }}

</template>

<script>
import EventBus from "@/event-bus";
import { de, en } from "vuejs-datepicker/dist/locale";
import { HostImages } from "@/graphql/hostImages/HostImages.ts";
import { AddressCountries } from "@/graphql/addressCountries/AddressCountries.ts";
import moment from "moment";
import BookingDataComponent from "@/components/bookingData/BookingDataComponent";
import ExclusionPeriodsComponent from "@/components/exclusionPeriods/ExclusionPeriodsComponent";
import { ChannelPropertyTypesEnum } from "@/utils/enums/channelPropertyTypes/ChannelPropertyTypesEnum.ts";
import OpenGdsComponent from "@/components/openGds/OpenGdsComponent";
import { EmailValidatorComponent } from "@/components/emailValidator/EmailValidatorComponent.ts";
import HyperGuestComponent from "../../components/hyperGuest/HyperGuestComponent";
import BookingDatePicker from "./datepicker/BookingDatePicker.vue";

export default {
  name: "customer_data",
  props: {
    voucher: {
      type: Object,
      required: true,
    },
    bookingRequest: {
      type: Object,
      required: true,
    },
    host: {
      type: Object,
      required: true,
    },
    loadVoucher: {
      type: Function,
      required: true,
    },
  },
  components: {
    BookingDatePicker,
  },
  data() {
    return {
      confirmationEmail: "",
      bookingDates: [undefined],
      selectedDatePicker: 0,
      hostImages: [],
      isBindingBooking: false,
      publicPath: process.env.BASE_URL,
      lang: localStorage.localeSettings == "en" ? en : de,
      disabledSpans: {
        ranges: [
          {
            from: moment().subtract(100, "years").toDate(),
            to: moment().add(100, "years").toDate(),
          },
        ],
        days: [],
        dates: [],
      },
      currentDate: moment().startOf("month").toDate(),
      addressCountries: [],

      rate: undefined,

      ChannelPropertyTypesEnum: ChannelPropertyTypesEnum,
    };
  },
  async mounted() {
    try {
      EventBus.$on(
        "changeLanguage",
        function (language) {
          this.lang = language == "en" ? en : de;
        }.bind(this)
      );
      this.loadAddressCountries();
      this.loadHostImages();
      this.loadExclusionPeriods();
    } catch (e) {
      console.error(e);
    }
  },
  methods: {
    async loadHostImages() {
      try {
        const hostImages = await this.$apollo
          .query({
            query: HostImages.Queries.HostImagesByProductId,
            variables: {
              productId: this.voucher.ProductId,
            },
          })
          .then(({ data }) => data?.hostImagesByProductId)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!hostImages) {
          return;
        }
        this.hostImages = hostImages;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadExclusionPeriods() {
      try {
        EventBus.$emit("changeCalendarLoading", true);
        const exclusionPeriodsComponent = new ExclusionPeriodsComponent();
        const exclusionPeriods =
          await exclusionPeriodsComponent.getExclusionPeriods(
            this.$apollo,
            this.voucher.Id,
            this.from,
            this.to
          );
        EventBus.$emit("changeCalendarLoading", false);
        if (!exclusionPeriods) {
          return;
        }
        const exclusionDays = [];
        for (const exclusionDay of exclusionPeriods.ExclusionDays) {
          exclusionDays.push(new Date(exclusionDay));
        }
        if (exclusionPeriods.ExclusionSpans) {
          exclusionPeriods.ExclusionSpans.forEach((e) => {
            e.from = new Date(e.from);
            e.to = new Date(e.to);
          });
        }
        this.disabledSpans = {
          ranges: exclusionPeriods.ExclusionSpans,
          days: exclusionPeriods.ExclusionWeekDays,
          dates: exclusionDays,
        };
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadAddressCountries() {
      try {
        const addressCountries = await this.$apollo
          .query({
            query: AddressCountries.Queries.AddressCountries,
          })
          .then(({ data }) => data?.addressCountries)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!addressCountries) {
          return;
        }
        this.addressCountries = addressCountries;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async saveBookingRequest() {
      try {
        const validatedBookingRequest = this.validBookingRequest(
          this.bookingRequest,
          this.bookingDates,
          this.host
        );
        if (!validatedBookingRequest) {
          return this.$alert(
            "Die Buchungsanfrage konnte nicht geprüft werden."
          );
        }
        if (!validatedBookingRequest.IsSuccess) {
          return this.$alert(validatedBookingRequest.Message);
        }

        let result;
        if (
          !this.host.ChannelPropertyTypeId ||
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook
        ) {
          result = await this._createBookingData();
        } else if (
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.OpenGDS
        ) {
          const bookingDate = this.bookingDates.find((d) => d);
          result = await this._createOpenGdsReservation(
            this.bookingRequest,
            bookingDate
          );
        } else if (
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest
        ) {
          const bookingDate = this.bookingDates.find((d) => d);
          result = await this._createHyperGuestBooking(
            this.bookingRequest,
            bookingDate
          );
        }
        if (!result) {
          return this.$alert(
            "Die Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es später erneut."
          );
        }
        if (result.Message) {
          this.$alert(result.Message);
        }
        if (result.IsSuccess) {
          await this.loadVoucher();
        }
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async _createBookingData() {
      try {
        let confirmationString = "customer-data.alerts.sendRequests";
        if (this.isBindingBooking) {
          confirmationString = "customer-data.alerts.confirmSaveRequests";
        }
        const confirmed = await this.$confirm(
          this.$t(confirmationString).toString()
        )
          .then()
          .catch();
        if (!confirmed) {
          return;
        }

        const bookingDataComponent = new BookingDataComponent();
        EventBus.$emit("changeLoadingState", true);
        const savedBookingData = await bookingDataComponent.saveBookingData(
          this.$apollo,
          this.bookingRequest,
          this.isBindingBooking,
          this.bookingDates
        );
        EventBus.$emit("changeLoadingState", false);
        if (!savedBookingData) {
          return this.$alert(
            `Ihre Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.`
          );
        }
        return savedBookingData;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _createOpenGdsReservation(bookingRequest, bookingDate) {
      try {
        if (!bookingRequest) {
          return {
            IsSuccess: false,
            Message: "Keine Buchungsanfrage gefunden.",
          };
        }
        if (!bookingDate) {
          return {
            IsSuccess: false,
            Message: "Bitte wählen Sie zuerst ein Buchungsdatum aus.",
          };
        }
        const openGdsComponent = new OpenGdsComponent();
        EventBus.$emit("changeLoadingState", true);
        const createdReservation = await openGdsComponent.createReservation(
          this.$apollo,
          bookingDate,
          bookingRequest
        );
        EventBus.$emit("changeLoadingState", false);
        if (!createdReservation) {
          return {
            IsSuccess: false,
            Message:
              "Ihre Buchungsdaten konnten nicht gespeichert werden, bitte versuchen Sie es zu einem späteren Zeitpunkt erneut.",
          };
        }
        return createdReservation;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async _createHyperGuestBooking(bookingRequest, bookingDate) {
      try {
        if (!bookingRequest) {
          return {
            IsSuccess: false,
            Message: "Keine Buchungsanfrage gefunden.",
          };
        }
        if (!bookingDate) {
          return {
            IsSuccess: false,
            Message: "Bitte wählen Sie zuerst ein Buchungsdatum aus.",
          };
        }
        const hyperGuestComponent = new HyperGuestComponent();
        EventBus.$emit("changeLoadingState", true);
        const createdBooking = await hyperGuestComponent.createBooking(
          this.$apollo,
          bookingRequest,
          bookingDate
        );
        EventBus.$emit("changeLoadingState", false);
        if (!createdBooking) {
          return {
            IsSuccess: false,
            Message: "Die Buchung konnte nicht gespeichert werden.",
          };
        }
        return createdBooking;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    validBookingRequest(bookingRequest, bookingDates, host) {
      try {
        if (!bookingRequest.Title) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.formOfAddress").toString(),
          };
        }
        if (!bookingRequest.FirstName) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.firstName").toString(),
          };
        }
        if (!bookingRequest.LastName) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.lastName").toString(),
          };
        }
        if (!bookingRequest.Street) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.streetAndNumber").toString(),
          };
        }
        if (!bookingRequest.ZipCode) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.zipCode").toString(),
          };
        }
        if (!bookingRequest.City) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.city").toString(),
          };
        }
        if (!bookingRequest.Country) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.country").toString(),
          };
        }
        if (!bookingRequest.Email) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.email").toString(),
          };
        }
        if (!this.confirmationEmail) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.repeatEmail").toString(),
          };
        }
        if (
          bookingRequest.Email.toLowerCase() !==
          this.confirmationEmail.toLowerCase()
        ) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.mailsDontMatch").toString(),
          };
        }
        const emailValidatorComponent = new EmailValidatorComponent();
        const isValidEmail = emailValidatorComponent.validateEmail(
          bookingRequest.Email
        );
        if (!isValidEmail) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.mailsNotValid").toString(),
          };
        }
        if (!host.ChannelPropertyTypeId != ChannelPropertyTypesEnum.TouriBook) {
          if (!bookingRequest.BirthDate) {
            return {
              IsSuccess: false,
              Message: this.$t("customer-data.alerts.birthDate").toString(),
            };
          }
          if (!bookingRequest.Phone) {
            return {
              IsSuccess: false,
              Message: this.$t("customer-data.alerts.phone").toString(),
            };
          }
        } else {
          if (bookingRequest.BirthDate) {
            bookingRequest.BirthDate = moment(
              bookingRequest.BirthDate
            ).toDate();
            if (
              bookingRequest.BirthDate &&
              !this.isValidDate(bookingRequest.BirthDate, true)
            ) {
              return {
                IsSuccess: false,
                Message: this.$t("customer-data.alerts.birthDate").toString(),
              };
            }
          } else {
            bookingRequest.BirthDate = null;
          }
        }
        if (bookingDates.some((b) => !b)) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotFilled").toString(),
          };
        }
        if (bookingDates.some((b) => !this.isValidDate(b))) {
          return {
            IsSuccess: false,
            Message: this.$t("customer-data.alerts.dataNotCorrect").toString(),
          };
        }
        return {
          IsSuccess: true,
        };
      } catch (e) {
        console.error(e);
        return {
          IsSuccess: false,
          Message: e.message,
        };
      }
    },
    selectDate(date) {
      try {
        if (this.bookingDates.find((d) => d && moment(d).isSame(date, "day"))) {
          return this.$alert(
            "Das Datum ist schon unter Ihren Wunschterminen, bitte wählen Sie ein anderes Datum aus."
          );
        }
        this.bookingDates[this.selectedDatePicker] = date;
        const _bookingDates = JSON.stringify(this.bookingDates);
        this.bookingDates = undefined;
        this.bookingDates = JSON.parse(_bookingDates);
        this.currentDate = date;
        if (
          !this.host.ChannelPropertyTypeId ||
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook
        ) {
          return;
        }
        this.loadRate();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    addDate() {
      try {
        if (this.bookingDates.length == 3) {
          return this.$alert(
            this.$t("customer-data.alerts.maxRequests").toString()
          );
        }
        this.bookingDates.push({
          Date: undefined,
        });
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    isValidDate(date, checkFutureDates) {
      try {
        if (!date) {
          return false;
        }
        if (date.toString() == "Invalid Date") {
          return false;
        }
        if (date instanceof Date && isNaN(date)) {
          return false;
        }
        let isValid = moment(date).isValid();
        if (!isValid) {
          return false;
        }
        const _date = new Date(date);
        if (Object.prototype.toString.call(_date) === "[object Date]") {
          if (isNaN(_date.getTime())) {
            return false;
          }
        } else {
          return false;
        }
        if (moment(date).year() < 1900) {
          return false;
        }
        if (moment(date).year() > moment().add(5, "years").year()) {
          return false;
        }
        if (!checkFutureDates) {
          return true;
        }
        if (moment(date).year() > moment().year()) {
          return false;
        }
        return true;
      } catch (e) {
        console.error(e);
        return false;
      }
    },
    changeDate(date) {
      try {
        if (date.year) {
          return;
        } else if (date.month) {
          this.currentDate = moment().month(date.month).toDate();
        } else if (moment(date).isValid()) {
          this.currentDate = date;
        }
        if (
          !this.host.ChannelPropertyTypeId ||
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.TouriBook
        ) {
          return;
        }
        this.loadExclusionPeriods();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async loadRate() {
      try {
        if (
          this.host.ChannelPropertyTypeId == ChannelPropertyTypesEnum.HyperGuest
        ) {
          const hyperGuestComponent = new HyperGuestComponent();
          EventBus.$emit("changeLoadingState", true);
          const rate = await hyperGuestComponent.getRate(
            this.$apollo,
            this.voucher.Id,
            this.currentDate
          );
          EventBus.$emit("changeLoadingState", false);
          if (!rate) {
            return this.$alert(
              `Es konnte keine Rate für ${moment(this.currentDate).format(
                "DD.MM.YYYY"
              )} geladen werden.`
            );
          }
          this.rate = rate;
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
  computed: {
    holidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.HolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.HolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.HolidayServices.split("\n");
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    additionalHolidayServices() {
      try {
        if (!this.voucher) {
          return [];
        }
        if (!this.voucher.AdditionalHolidayServices) {
          return [];
        }
        const holidayServices = this.voucher.AdditionalHolidayServices;
        if (!holidayServices) {
          return [];
        }
        return this.voucher.AdditionalHolidayServices.split("\n");
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    productImage() {
      try {
        try {
          const articleImage = this.hostImages.find(
            (i) =>
              i.Image.ImageTypeId == 6 ||
              i.Image.ImageName.toLowerCase().includes("artikelbild")
          );
          if (!articleImage) {
            return undefined;
          }
          return articleImage.RemoteBaseUrl + "large.jpg";
        } catch (e) {
          console.log(e);
          this.$alert(e.message);
          return undefined;
        }
      } catch (e) {
        console.log(e);
        return undefined;
      }
    },
    from() {
      try {
        return moment(this.currentDate).startOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
    to() {
      try {
        return moment(this.currentDate).endOf("month").toDate();
      } catch (e) {
        console.error(e);
        return undefined;
      }
    },
  },
};
</script>

<style scoped></style>
