import gql from "graphql-tag";

export const Maintenances = {
    Queries: {
        TouriBookMaintenance: gql`
        query touriBookMaintenance {
            touriBookMaintenance {
                Platform
                Description
                IsActive
            }
        }`,
    }
};
