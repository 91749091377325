const moment = require("moment");

class FormatDateComponent {
  static formatDateString(date, days) {
    try {
      if (!date) {
        return "";
      }
      if (days == undefined) {
        return "";
      }
      moment.locale(localStorage.localeSettings);
      const momentDate = moment(date).add(days, "days");
      return momentDate.format("dd") + " " + momentDate.format("L");
    } catch (e) {
      console.log(e);
      return "";
    }
  }

  static formatDate(input, withTime = true) {
    try {
      if (!input) {
        return "";
      }
      moment.locale(localStorage.localeSettings);
      const date = moment(input).format("L");
      const time = moment(input).format("LT");
      return withTime ? date + " " + time : date;
    } catch (e) {
      console.log(e);
      return "";
    }
  }
}

module.exports = FormatDateComponent;
