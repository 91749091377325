const {
  BookingRequests,
} = require("@/graphql/bookingRequests/BookingRequests.ts");
const {
  BookingTicketStatusEnum,
} = require("@/utils/enums/bookingTicketStatus/BookingTicketStatusEnum");
const {
  BookingTickets,
} = require("@/graphql/bookingTickets/BookingTickets.ts");
const {
  BookingDateRequestStatusEnum,
} = require("@/utils/enums/bookingDateRequestStatus/BookingDateRequestStatusEnum");
const {
  BookingDateRequests,
} = require("@/graphql/bookingDateRequests/BookingDateRequests.ts");
const moment = require("moment");
const {
  BookingTicketEmailLogs,
} = require("@/graphql/bookingTicketEmailLogs/BookingTicketEmailLogs.ts");
const {
  BookingTicketEmailLogEmailsEnum,
} = require("@/utils/enums/bookingTicketEmailLogEmails/BookingTicketEmailLogEmailsEnum.ts");
const { Vouchers } = require("@/graphql/vouchers/Vouchers.ts");
const VoucherStatusEnum = require("@/utils/enums/voucherStatus/VoucherStatusEnum");

class BookingDataComponent {
  async saveBookingData(
    apolloClient,
    bookingRequest,
    isBindingBooking,
    bookingDates
  ) {
    try {
      if (!apolloClient) {
        return {
          IsSuccess: false,
          Message: `Keine Datenbankschnittstelle übergeben.`,
        };
      }
      if (!bookingRequest) {
        return {
          IsSuccess: false,
          Message: `Keine Buchungsanfrage zum speichern gefunden.`,
        };
      }
      if (!bookingDates) {
        return {
          IsSuccess: false,
          Message: `Keine Buchungsdaten zum speichern gefunden.`,
        };
      }
      const savedBookingRequest = await apolloClient
        .mutate({
          mutation: BookingRequests.Mutations.SaveBookingRequest,
          fetchPolicy: "no-cache",
          variables: {
            bookingRequest: bookingRequest,
          },
        })
        .then(({ data }) => data.saveBookingRequest)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!savedBookingRequest) {
        return {
          IsSuccess: false,
          Message: `Die Buchungsanfrage konnte nicht gespeichert werden.`,
        };
      }
      if (!savedBookingRequest.IsSuccess) {
        return savedBookingRequest;
      }
      const bookingTicket = {
        BookingRequestId: savedBookingRequest.StringData,
        BookingTicketStatusId: BookingTicketStatusEnum.DatesRequested,
        IsBindingBooking: isBindingBooking,
      };
      const savedBookingTicket = await apolloClient
        .mutate({
          mutation: BookingTickets.Mutations.SaveBookingTicket,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicket: bookingTicket,
          },
        })
        .then(({ data }) => data?.saveBookingTicket)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!savedBookingTicket) {
        return {
          IsSuccess: false,
          Message: `Das Buchungsticket konnte nicht gespeichert werden.`,
        };
      }
      if (!savedBookingTicket.IsSuccess) {
        return savedBookingTicket;
      }
      for (const bookingDate of bookingDates) {
        const bookingDateRequest = {
          Date: bookingDate,
          BookingDateRequestStatusId:
            BookingDateRequestStatusEnum.DateRequested,
          BookingTicketId: savedBookingTicket.StringData,
        };
        const savedBookingDateRequest = await apolloClient
          .mutate({
            mutation: BookingDateRequests.Mutations.SaveBookingDateRequest,
            fetchPolicy: "no-cache",
            variables: {
              bookingDateRequest: bookingDateRequest,
            },
          })
          .then(({ data }) => data?.saveBookingDateRequest)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!savedBookingDateRequest) {
          return {
            IsSuccess: false,
            Message: `Die Buchungsdatumanfrage für ${moment(
              bookingDateRequest.Date
            ).format("DD.MM.YYYY")} konnte nicht gespeichert werden.`,
          };
        }
        if (!savedBookingDateRequest.IsSuccess) {
          return savedBookingDateRequest;
        }
      }
      let sentBookingTicketEmailLogEmail = await apolloClient
        .mutate({
          mutation:
            BookingTicketEmailLogs.Mutations.SendBookingTicketEmailLogEmail,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: savedBookingTicket.StringData,
            bookingTicketEmailLogEmail:
              BookingTicketEmailLogEmailsEnum.HostBookingDatesRequestedEmail,
          },
        })
        .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!sentBookingTicketEmailLogEmail) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
        };
      }
      if (!sentBookingTicketEmailLogEmail.IsSuccess) {
        return sentBookingTicketEmailLogEmail;
      }
      sentBookingTicketEmailLogEmail = await apolloClient
        .mutate({
          mutation:
            BookingTicketEmailLogs.Mutations.SendBookingTicketEmailLogEmail,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: savedBookingTicket.StringData,
            bookingTicketEmailLogEmail:
              BookingTicketEmailLogEmailsEnum.CustomerBookingDatesRequestedEmail,
          },
        })
        .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!sentBookingTicketEmailLogEmail) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
        };
      }
      if (!sentBookingTicketEmailLogEmail.IsSuccess) {
        return sentBookingTicketEmailLogEmail;
      }
      return {
        IsSuccess: true,
        Message: `Ihre Buchungsdaten wurden erfolgreich gespeichert.`,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }

  async saveAcceptedBookingDateRequest(apolloClient, bookingDateRequestId) {
    try {
      if (!apolloClient) {
        return {
          IsSuccess: false,
          Message: `Keine Datenbankschnittstelle übergeben.`,
        };
      }
      if (!bookingDateRequestId) {
        return {
          IsSuccess: false,
          Message: `Keine Buchungsdatumsanfrage gefunden.`,
        };
      }
      let bookingDateRequest = await apolloClient
        .query({
          query: BookingDateRequests.Queries.BookingDateRequest,
          fetchPolicy: "no-cache",
          variables: {
            id: bookingDateRequestId,
          },
        })
        .then(({ data }) => data?.bookingDateRequest)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingDateRequest) {
        return {
          IsSuccess: false,
          Message: `Keine Buchungsdatumsanfrage für ${bookingDateRequestId} gefunden.`,
        };
      }
      const bookingTicket = await apolloClient
        .query({
          query: BookingTickets.Queries.BookingTicket,
          fetchPolicy: "no-cache",
          variables: {
            id: bookingDateRequest.BookingTicketId,
          },
        })
        .then(({ data }) => data?.bookingTicket)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingTicket) {
        return {
          IsSuccess: false,
          Message: `Es konnte kein Buchungsticket für ${bookingDateRequest.BookingTicketId} geladen werden.`,
        };
      }
      let bookingDateRequests = await apolloClient
        .query({
          query:
            BookingDateRequests.Queries.BookingDateRequestsByBookingTicketId,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicket.Id,
          },
        })
        .then(({ data }) => data?.bookingDateRequestsByBookingTicketId)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingDateRequests) {
        return {
          IsSuccess: false,
          Message: `Es konnten keine Buchungsdatumsanfragen für Buchungsticket ${bookingTicket.Id} geladen werden.`,
        };
      }
      const bookingRequest = await apolloClient
        .query({
          query: BookingRequests.Queries.BookingRequest,
          fetchPolicy: "no-cache",
          variables: {
            id: bookingTicket.BookingRequestId,
          },
        })
        .then(({ data }) => data?.bookingRequest)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!bookingRequest) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine Buchungsanfrage für ${bookingTicket.BookingRequestId} geladen werden.`,
        };
      }
      const voucher = await apolloClient
        .query({
          query: Vouchers.Queries.Voucher,
          fetchPolicy: "no-cache",
          variables: {
            id: bookingRequest.VoucherId,
          },
        })
        .then(({ data }) => data?.voucher)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!voucher) {
        return {
          IsSuccess: false,
          Message: `Es konnte kein Gutschein für ${bookingRequest.VoucherId} geladen werden.`,
        };
      }

      // DATE REQUEST TO ACCEPT
      bookingDateRequest = bookingDateRequests.find(
        (b) => b.Id == bookingDateRequestId
      );
      if (!bookingDateRequest) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine Buchungsdatumsanfrage zum annehmen herausgefiltert werden.`,
        };
      }
      bookingDateRequest.BookingDateRequestStatusId =
        BookingDateRequestStatusEnum.DateBooked;

      // DATE REQUESTS TO REJECT
      bookingDateRequests = bookingDateRequests.filter(
        (b) => b.Id != bookingDateRequestId
      );
      if (!bookingDateRequests) {
        return {
          IsSuccess: false,
          Message: `Es konnten keine Buchungsdatumsanfragen zum ablehnen herausgefiltert werden.`,
        };
      }
      bookingDateRequests.forEach(
        (b) =>
          (b.BookingDateRequestStatusId =
            BookingDateRequestStatusEnum.DateRejected)
      );
      bookingDateRequests.push(bookingDateRequest);

      for (const _bookingDateRequest of bookingDateRequests) {
        const savedBookingDateRequest = await apolloClient
          .mutate({
            mutation: BookingDateRequests.Mutations.SaveBookingDateRequest,
            fetchPolicy: "no-cache",
            variables: {
              bookingDateRequest: _bookingDateRequest,
            },
          })
          .then(({ data }) => data?.saveBookingDateRequest)
          .catch((e) => {
            console.error(e);
            return undefined;
          });
        if (!savedBookingDateRequest) {
          return {
            IsSuccess: false,
            Message: `Die Buchungsdatumsanfrage ${bookingDateRequest.Id} konnte nicht gespeichert werden.`,
          };
        }
        if (!savedBookingDateRequest.IsSuccess) {
          return savedBookingDateRequest;
        }
      }

      bookingTicket.BookingTicketStatusId = BookingTicketStatusEnum.DatesBooked;
      const savedBookingTicket = await apolloClient
        .mutate({
          mutation: BookingTickets.Mutations.SaveBookingTicket,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicket: bookingTicket,
          },
        })
        .then(({ data }) => data?.saveBookingTicket)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!savedBookingTicket) {
        return {
          IsSuccess: false,
          Message: `Das Buchungsticket ${bookingTicket.Id} konnte nicht gespeichert werden.`,
        };
      }
      if (!savedBookingTicket.IsSuccess) {
        return savedBookingTicket;
      }

      voucher.Status = VoucherStatusEnum.Booked;
      voucher.BookedAt = bookingDateRequest.Date;
      const savedVoucher = await apolloClient
        .mutate({
          mutation: Vouchers.Mutations.SaveVoucher,
          fetchPolicy: "no-cache",
          variables: {
            voucher: voucher,
          },
        })
        .then(({ data }) => data?.saveVoucher)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!savedVoucher) {
        return {
          IsSuccess: false,
          Message: `Gutschein ${voucher.Id} konnte nicht gespeichert werden.`,
        };
      }
      if (!savedVoucher.IsSuccess) {
        return savedVoucher;
      }
      let sentBookingTicketEmailLogEmail = await apolloClient
        .mutate({
          mutation:
            BookingTicketEmailLogs.Mutations.SendBookingTicketEmailLogEmail,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicket.Id,
            bookingTicketEmailLogEmail:
              BookingTicketEmailLogEmailsEnum.HostBookingDatesBookedEmail,
          },
        })
        .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!sentBookingTicketEmailLogEmail) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
        };
      }
      if (!sentBookingTicketEmailLogEmail.IsSuccess) {
        return sentBookingTicketEmailLogEmail;
      }
      sentBookingTicketEmailLogEmail = await apolloClient
        .mutate({
          mutation:
            BookingTicketEmailLogs.Mutations.SendBookingTicketEmailLogEmail,
          fetchPolicy: "no-cache",
          variables: {
            bookingTicketId: bookingTicket.Id,
            bookingTicketEmailLogEmail:
              BookingTicketEmailLogEmailsEnum.CustomerBookingDatesBookedEmail,
          },
        })
        .then(({ data }) => data?.sendBookingTicketEmailLogEmail)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!sentBookingTicketEmailLogEmail) {
        return {
          IsSuccess: false,
          Message: `Es konnte keine E-Mail an den Gastgeber bzgl. Ihrer Buchungsanfrage versendet werden.`,
        };
      }
      if (!sentBookingTicketEmailLogEmail.IsSuccess) {
        return sentBookingTicketEmailLogEmail;
      }
      return {
        IsSuccess: true,
        Message: `Ihre Buchungsdaten wurden erfolgreich gespeichert.`,
      };
    } catch (e) {
      console.error(e);
      return {
        IsSuccess: false,
        Message: e.message,
      };
    }
  }
}

module.exports = BookingDataComponent;
