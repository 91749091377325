import gql from "graphql-tag";

export const BookingTicketConversationMessages = {
    Queries: {
        BookingTicketConversationMessages: gql`
        query bookingTicketConversationMessages($bookingTicketConversationId: ID) {
            bookingTicketConversationMessages(bookingTicketConversationId: $bookingTicketConversationId) {
                Id
                Guid
                BookingTicketConversationId
                BookingTicketConversationMessageTypeId
                Message
                CreatedAt
            }
        }`,
    },
    Mutations: {
        CreateBookingTicketConversationMessage: gql`
        mutation createBookingTicketConversationMessage($bookingTicketId: ID, $bookingTicketConversationMessage: BookingTicketConversationMessagesInput) {
            createBookingTicketConversationMessage(bookingTicketId: $bookingTicketId, bookingTicketConversationMessage: $bookingTicketConversationMessage)
        }`,
    },
}
