import { LoginVoucherMutation } from "../graphql/mutations/voucherLogin/LoginVoucherMutation";

export default {
  login(apolloClient, voucherId, code, callback) {
    try {
      if (!apolloClient) {
        callback({ Message: "Es wurde keine Datenbankverbindung übergeben!" });
        return;
      }
      if (!voucherId) {
        callback({ Message: "Es wurde keine Gutscheinnummer übergeben!" });
        return;
      }
      if (!code) {
        callback({ Message: "Es wurde kein Gutscheincode übergeben!" });
        return;
      }
      apolloClient
        .mutate({
          mutation: LoginVoucherMutation,
          variables: {
            id: voucherId,
            code: code,
          },
        })
        .then((response) => {
          if (!response || !response.data || !response.data.LoginVoucher) {
            return callback({
              Authenticated: false,
              Message: "Es konnte keine Login-Session erstellt werden!",
            });
          }
          return callback(response.data.LoginVoucher);
        })
        .catch((error) => {
          console.log(error);
          callback({ Message: error.message });
        });
    } catch (e) {
      console.log(e);
      callback({ Message: e.message });
    }
  },
};
