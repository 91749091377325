const {
  ExclusionPeriods,
} = require("@/graphql/exclusionPeriods/ExclusionPeriods.ts");

class ExclusionPeriodsComponent {
  async getExclusionPeriods(apollo, voucherId, from, to) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!voucherId) {
        return undefined;
      }
      if (!from) {
        return undefined;
      }
      if (!to) {
        return undefined;
      }
      const exclusionPeriods = await apollo
        .query({
          query: ExclusionPeriods.Queries.ExclusionPeriods,
          fetchPolicy: "no-cache",
          variables: {
            voucherId: voucherId,
            from: from,
            to: to,
          },
        })
        .then(({ data }) => data?.exclusionPeriods)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!exclusionPeriods) {
        return undefined;
      }
      return exclusionPeriods;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = ExclusionPeriodsComponent;
