import gql from "graphql-tag";

export const BookingTicketEmailLogs = {
    Mutations: {
        SendBookingTicketEmailLogEmail: gql`
        mutation sendBookingTicketEmailLogEmail($bookingTicketEmailLogEmail: ID, $bookingTicketId: ID) {
            sendBookingTicketEmailLogEmail(bookingTicketEmailLogEmail: $bookingTicketEmailLogEmail, bookingTicketId: $bookingTicketId) {
                IsSuccess
                Message
            }
        }`,
    }
};
