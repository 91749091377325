const { UserGroups } = require("@/graphql/userGroups/UserGroups.ts");

class UserGroupsComponent {
  async getUserGroupByIndicator(apollo, indicator) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!indicator) {
        return undefined;
      }
      const userGroup = await apollo
        .query({
          query: UserGroups.Queries.UserGroupByIndicator,
          variables: {
            indicator: indicator.toUpperCase(),
          },
        })
        .then(({ data }) => data?.userGroupByIndicator)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!userGroup) {
        return undefined;
      }
      return userGroup;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = UserGroupsComponent;
