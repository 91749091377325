const { Hosts } = require("@/graphql/hosts/Hosts.ts");

class HostsComponent {
  async getHost(apollo, hostId) {
    try {
      if (!apollo) {
        return undefined;
      }
      if (!hostId) {
        return undefined;
      }
      const host = await apollo
        .query({
          query: Hosts.Queries.Host,
          fetchPolicy: "no-cache",
          variables: {
            id: hostId,
          },
        })
        .then(({ data }) => data?.host)
        .catch((e) => {
          console.error(e);
          return undefined;
        });
      if (!host) {
        return undefined;
      }
      return host;
    } catch (e) {
      console.error(e);
      return undefined;
    }
  }
}

module.exports = HostsComponent;
